<template>
  <v-container>
    <div id="forgotpassword" style="height: 100%">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5" lg="4">
          <v-card elevation="12" style="margin-top: calc(50vh - 268px)">
            <v-progress-linear
              :active="!loaded"
              indeterminate
              absolute
              top
            ></v-progress-linear>
            <v-card-text style="padding: 40px 40px 40px 40px">
              <div class="text-center mb-4">
              </div>

              <div v-show="!forgotPasswordCompleted">
                <div class="mb-5">
                  Enter your email to initiate password reset.
                </div>
                <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                  <v-text-field
                    label="Email"
                    placeholder="Enter email address"
                    name="email"
                    class="mb-6"
                    type="text"
                    required
                    :rules="[requiredRule, emailRule]"
                    v-model="form.email"
                  ></v-text-field>

                  <v-btn
                    class="primary"
                    type="submit"
                    large
                    min-width="200"
                    block
                    >Reset password</v-btn
                  >

                  <v-btn
                    type="submit"
                    class="primary--text mt-4"
                    text
                    large
                    min-width="200"
                    block
                    @click="onCancelClick"
                    >Cancel</v-btn
                  >
                </v-form>
              </div>

              <div v-show="forgotPasswordCompleted">
                <div class="mb-6">
                  A mail will be sent to
                  <strong>{{ forgotPasswordCompleted }}</strong
                  >, with instructions for resetting your password.<br /><br /><em
                    >If you haven't received an email within 5 minutes please check your spam folder.</em
                  ><br /><br />
                </div>
                <v-btn
                  class="primary"
                  type="button"
                  large
                  min-width="200"
                  block
                  @click="onBackToLoginClick"
                  >Back to login</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, email } from "../utils/FormRules.js";

export default {
  name: "ForgotPassword",
  components: {},
  data: () => ({
    valid: false,
    form: {
      email: "",
    },
    requiredRule: required,
    emailRule: email,
  }),
  methods: {
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.forgotPassword(this.form.email);
      }
    },
    onCancelClick() {
      this.$router.replace("/login");
    },
    onBackToLoginClick() {
      this.$router.replace("/login");
    },
    ...mapActions(["forgotPassword"]),
  },
  computed: {
    ...mapState(["loaded", "forgotPasswordCompleted"]),
  },
};
</script>
